<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo brand-logo-unlogin">
        <!-- <vuexy-logo /> -->
        <img
          src="@/assets/images/logo/transfersmile_big_logo.png"
          alt=""
        >
        <h2 class="brand-text text-primary ml-1 mt-2">
          Merchant Center
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-2"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to Merchant Center!
            <!-- 👋 -->
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
          >
            <b-form
              class="auth-login-form mt-5"
              @submit.prevent="login"
            >
              <!-- Phone number -->
              <!-- <b-form-group
                label="Phone number"
                label-for="phone number"
              >
                <phone-number-input
                  ref="phoneNumberComponent"
                  source="login"
                  :phone-number.sync="phoneNumber"
                  :area-code.sync="areaCode"
                />
              </b-form-group> -->

              <b-form-group label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="loginEmail"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    placeholder="Email"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >

                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <!-- 暂时不做 忘记密码 -->
                    <!-- <b-link :to="{name:'auth-forgot-password'}">
                      <small>Forgot Password?</small>
                    </b-link> -->
                  </div>

                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                      autocomplete="new-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Google Verification Code -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Google Verification Code</label>
                  <b-link @click.stop.prevent="linkFunc">
                    <small>Link?</small>
                  </b-link>
                </div>
                <b-form-input
                  v-model="verificationCode"
                  type="number"
                  class="form-control-merge"
                  placeholder="Please enter the verification code..."
                  autocomplete="off"
                  :formatter="value => value.slice(0,6)"
                />
                <small
                  v-if="!isAuthCode && verificationCode === ''"
                  class="text-danger"
                >The Google Verification Code field is required</small>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                class="mt-3"
                type="submit"
                variant="primary"
                block
              >
                Login
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <b-link @click="goToRegister">
              <span>&nbsp;Register</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>

    <!-- Register for Google Authenticator -->
    <b-modal
      ref="googleAuth"
      ok-only
      ok-title="Okey"
      centered
      title="Register for Google Authenticator"
      hide-footer
    >
      <b-card-text class="login-modal-p">
        1. Download the 'Google Authenticator' APP
      </b-card-text>
      <b-card-text class="login-modal-p">
        2. Scan the QR code below
      </b-card-text>
      <div class="login-qrcode">
        <img
          :src="qrCodeImageUrl"
          alt="qr code"
        >
      </div>
      <b-button
        class="mt-2 mb-2 login-modal-okey"
        variant="primary"
        block
        @click="hideModal"
      >
        Okey
      </b-button>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
  // BInputGroupPrepend,
  // BDropdown,
  // BDropdownItem,
  // BDropdownDivider,
  // BFormCheckbox,
  // BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import {
  aseEncrypt, showToast, updateNavMenuItems, getDefaultName,
} from '@/libs/utils'
// import PhoneNumberInput from './components/PhoneNumberInput.vue'
// const _ = require('lodash')

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    // PhoneNumberInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loginEmail: '', // gev08144@uzxia.com
      password: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      qrCodeImageUrl: '',
      verificationCode: '',
      // phoneNumber: '',
      // areaCode: '+55',
      // validation rules
      required,
      email,
      isAuthCode: true,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    // 在登录页去掉注册和完善信息时缓存的数据字段
    // this.$storage.clearItem('temporary-data')
    // this.$storage.clearAll()
    if (window.location.origin.includes('localhost')) { this.loginEmail = 'gev08144@uzxia.com'; this.password = 'Qaz1234' }
  },
  provide() {
    return {
      scene: '4',
    }
  },
  methods: {
    login() {
      const proxy = this
      proxy.isAuthCode = false
      this.$refs.loginForm.validate().then(async success => {
        if (success && this.verificationCode !== '') {
          const { loginEmail, password, verificationCode } = this
          // 请求登陆接口
          proxy.$showLoading()
          const params = {
            login_type: 2, // 1验证码 2密码
            platform: 2, // 1用户端 2商户端
            user_name: loginEmail,
            password: aseEncrypt(password),
            auth_code: verificationCode,
          }
          const resp = await this.$api.login(params).catch(error => {
            proxy.$hideLoading()
            showToast({ proxy, title: 'danger', message: error.message })
          })
          this.$hideLoading()
          const { code, data, message } = resp.data
          if (code === 200) {
            proxy.$storage.setItem('accessToken', data.token)
            proxy.$storage.setItem('refreshToken', data.token)
            // const userData = {
            //   id: 1, fullName: 'John Doe', username: 'johndoe', fullName: phone, username: phone, avatar: 'img/13-small.d796bffd.png', email: 'admin@demo.com', role: 'admin', ability: [{ action: 'manage', subject: 'all' }], extras: { eCommerceCartItemsCount: 5 },
            // }
            // *** 权限相关开始 ***
            const navMenuItems = await updateNavMenuItems(proxy)
            proxy.$storage.setItem('navMenuItems', JSON.stringify(navMenuItems))
            const [defaultRouteName] = getDefaultName()
            this.$store.commit('wallet/UPDATE_DEFAULTROUTENAME', defaultRouteName)
            this.$storage.setItem('defaultRouteName', defaultRouteName)
            // 获取当前的左侧目录 this.$store.commit('wallet/UPDATE_NAVMENUITEMS', navMenuItems)
            setTimeout(() => {
              this.$store.dispatch('wallet/changeNavMenuItems', navMenuItems)
            }, 500)
            // *** 权限相关结束 ***
            // kyc_status: 0 未提交 2 拒绝 3 处理中 4 通过 5 重新上传
            if (window.location.origin.includes('localhost')) { data.kyc_status = 3 }
            if (data.kyc_status === 0 || data.kyc_status === 2 || data.kyc_status === 5) {
              proxy.$router.push({ name: 'account-verification' })
              return
            }
            // .replace(getHomeRouteForLoggedInUser(userData.role, response.data))
            setTimeout(() => {
              proxy.$router.push({ name: store.state.wallet.defaultRouteName || proxy.$storage.getItem('defaultRouteName') }).then(() => {
                proxy.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Welcome',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'You have successfully logged in.',
                    // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
              })
            }, 10)
            // 信息是否完善 profile_need_update true 表示 需要更新
            // isInformationComplete 信息是否完善 false 表示信息不完善 === profile_need_update true
            // res.data.profile_need_update;
            // response.data.isInformationComplete = !res.data.profile_need_update // 正确的
            // if (!response.data.isInformationComplete) {
            //   proxy.$storage.setItem(
            //     'temporary-data',
            //     JSON.stringify(response.data),
            //   )
            //   // 临时缓存data,完善信息后跳转缓存
            //   setTimeout(() => {
            //     proxy.$router.replace(
            //       getHomeRouteForLoggedInUser('', response.data),
            //     )
            //   }, 500)
            //   return
            // }
            // const response = {
            //   status: 200,
            //   data: {
            //     userData: {
            //       id: 1,
            //       // fullName: "John Doe",
            //       // username: "johndoe",
            //       fullName: loginEmail,
            //       username: loginEmail,
            //       avatar: 'img/13-small.d796bffd.png',
            //       email: loginEmail,
            //       role: 'admin',
            //       ability: [{ action: 'manage', subject: 'all' }],
            //       extras: { eCommerceCartItemsCount: 5 },
            //     },
            //     accessToken: token,
            //     refreshToken: token,
            //   },
            //   config: {
            //     url: '/jwt/login',
            //     method: 'post',
            //     data: '{"email":"admin@demo.com","password":"admin@demo.com"}',
            //     headers: {
            //       Accept: 'application/json, text/plain, */*',
            //       'Content-Type': 'application/json;charset=utf-8',
            //     },
            //     transformRequest: [null],
            //     transformResponse: [null],
            //     timeout: 0,
            //     xsrfCookieName: 'XSRF-TOKEN',
            //     xsrfHeaderName: 'X-XSRF-TOKEN',
            //     maxContentLength: -1,
            //     maxBodyLength: -1,
            //   },
            //   request: { responseURL: '/jwt/login' },
            // }
          } else {
            showToast({ proxy, title: 'danger', message })
          }
        }
      })
    },
    goToRegister() {
      // proxy.$storage.setItem('temporary-data', JSON.stringify({ isInformationComplete: true }))
      this.$router.replace({
        name: 'auth-register',
        params: { data: { isInformationComplete: true } },
      })
    },
    linkFunc() {
      new Promise(resolve => {
        this.isAuthCode = true
        resolve(true)
      }).then(() => {
        this.$refs.loginForm.validate().then(async success => {
          if (success) {
            const { loginEmail, password } = this
            const params = {
              login_type: 2,
              platform: 2,
              user_name: loginEmail,
              password: aseEncrypt(password),
            }
            this.$showLoading()
            try {
              const res = await this.$api.getQrcode(params).catch(error => {
                showToast({ proxy: this, title: 'danger', message: error.message })
              })
              this.$hideLoading()
              const { code, data, message } = res.data
              if (code !== 200) {
                showToast({ proxy: this, title: 'danger', message })
                return
              }
              if (data) {
                this.qrCodeImageUrl = `data:image/png;base64,${data.qr_code}`
                this.$refs.googleAuth.show()
              } else {
                showToast({ proxy: this, title: 'danger', message: 'data is Null' })
              }
            } catch (error) {
              this.$hideLoading()
            }
          }
        })
      })
    },
    getBase64(data) {
      return new Promise((resolve, reject) => {
        const blob = new Blob([data], { type: 'image/png' })
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    hideModal() {
      this.$refs.googleAuth.hide()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.brand-logo-unlogin img {
  width: auto;
  height: 70px;
}
.auth-wrapper {
  .input-group {
    flex-wrap: nowrap !important;
  }
  .input-group-prepend {
    height: 38px;
  }
  .b-dropdown-country {
    img {
      width: 20px;
      height: auto;
    }
    & > button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .validate-span-box {
    display: flex;
    width: 100%;
    flex-wrap: nowrap !important;
  }
  input[name="login-email"] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.login-modal-p {
  margin: 10px 0 0 10px;
  font-weight: 500;
}
.login-qrcode {
  border: 2px solid #897ef2;
  margin: 0 auto;
  width: 220px;
  height: 220px;
  margin-top: 20px;
  img {
    width: 100%;
  }
}
.login-modal-okey {
  width: 150px;
  margin: auto;
}
</style>
